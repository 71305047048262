@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(./fonts/calibri-regular.ttf) format('ttf');
}
@font-face {
  font-family: 'Calibri Bold';
  src: local('Calibri Bold'), url(./fonts/calibri-bold.TTF) format('ttf');
}

@tailwind utilities;
